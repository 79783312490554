import React, { FC } from 'react';

import { Layout } from '@components/layout';
import FailPageComponent from '@features/failure/FailPageComponent';

const NotFoundPage: FC = () => (
  <Layout>
    <FailPageComponent />
  </Layout>
);
export default NotFoundPage;
