import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Tasty } from '../../../assets/image/png';
import * as Style from '../FallbackComponent/style';

import { Description, NoIndex, title } from '@components/layout/SEO';

const FailPageComponent = () => (
  <Style.Wrapper>
    <Helmet>
      <title>Error | {title}</title>
      {Description}
      {NoIndex}
    </Helmet>
    <img src={Tasty as string} alt="Tasty Logo" />
    <Style.Text>
      <div>SORRY...</div>
      <div>
        <FormattedMessage id="error.title2" />
      </div>
    </Style.Text>
    <Style.Description>
      <div>
        <FormattedMessage id="error.message3" />
      </div>
      <div>
        <FormattedMessage id="error.message4" />
      </div>
    </Style.Description>
    <h1 style={{ marginTop: '2rem', textAlign: 'center' }}>
      Try to search or go to <Link to="/">Hom Page</Link>
    </h1>
  </Style.Wrapper>
);

export default React.memo(FailPageComponent);
